.video-poster {
    position: relative;
    background: black;
    width: 100%;

    video,
    img,
    picture {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }

    &--filter-bw {
        img {
            filter: grayscale(1);
            opacity: 0.8;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &:hover {
        cursor: pointer;

        &::before {
            transform: translateY(-50%) translateX(-50%) scale(1.2);
        }
    }

    &::before {
        content: url("data:image/svg+xml; utf8, %3Csvg width='51' height='50' viewBox='0 0 51 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.6383 48.5305C39.2457 48.5305 50.2766 37.9397 50.2766 24.8754C50.2766 11.811 39.2457 1.22021 25.6383 1.22021C12.0309 1.22021 1 11.811 1 24.8754C1 37.9397 12.0309 48.5305 25.6383 48.5305Z' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21.3633 19.072L30.2177 24.6306L21.3633 30.1892V19.072Z' fill='white'/%3E%3C/svg%3E%0A");
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50px;
        height: 50px;
        transition: transform 0.3s ease-in;
        transform: translateY(-50%) translateX(-50%);
        z-index: 1;
    }

    &--testimonial {
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
                    270.14deg,
                    rgba(255, 255, 255, 0.42) 0.11%,
                    rgba(255, 255, 255, 0) 67.64%
                ),
                linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
        }
    }
}
