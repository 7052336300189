:root {
  @include MQ-Above(medium) {
    --warranty__media__order: 1;
    --warranty__text__order: 2;
    --warranty__text__transform: translateX(-11%);
    --warranty__text__bg-color: transparent;
  }
}

.warranty {
  &__text {
    @include Container(medium);
  }

  &__quote {
    &__inner {
      @include Container($padding_desktop: 0, $padding_mobile: 0);
      @include Grid(1fr 1fr, $gap_desktop: 0, $gap_mobile: 0);
      align-items: center;
    }

    &__text {
      @extend .title, .title--tertiary, .title--font-family--secondary;
      font-style: italic;
      transform: var(--warranty__text__transform);
      padding: var(--tokens__padding);
      background: var(
        --warranty__text__bg-color,
        var(--tokens__colors__light-blue)
      );

      order: var(--warranty__text__order, 1);
    }

    &__media {
      order: var(--warranty__media__order, 2);
    }
  }
}
