.site-login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(52, 54, 66, 0.9);
  z-index: 100;
  overflow-y: auto;
  cursor: pointer;
  transition: opacity 0.3s 0, visibility 0 0.3s;

  &[aria-hidden="true"] {
    display: none;
  }

  &__container {
    position: relative;
    width: 90%;
    max-width: 600px;
    background: white;
    margin: 2rem auto;
    padding: 2rem 2rem 2.5rem;

    label {
      font-size: 0.85rem;
      margin: 1.5rem 0 0.5rem;
      display: block;
    }

    input[type="email"],
    input[type="text"],
    input[type="password"] {
      border: 1px solid var(--tokens__colors__light-grey);
    }

    input[type="submit"] {
      margin-top: 2rem;
      width: 100%;
    }

    .title {
      text-align: center;
    }
  }
}
