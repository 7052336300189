:root {
  --fabrics-grid-columns: repeat(2, 1fr);

  @include MQ-Above(small-medium) {
    --fabrics-grid-columns: repeat(4, 1fr);
  }

  @include MQ-Above(medium) {
    --fabrics-grid-columns: repeat(5, 1fr);
  }

  @include MQ-Above(large) {
    --fabrics-grid-columns: repeat(6, 1fr);
  }
}

.fabrics {
  &__inner {
    @include Container();
    @include Grid(2fr 5fr);
  }

  &__results {
    display: grid;
  }

  &__filters {
    padding: 1.875rem;
    box-shadow: var(--tokens__box-shadow);
    align-self: flex-start;

    > header {
      margin-bottom: 1.875rem;
    }

    ul {
      li + li {
        margin-top: 0.625rem;
      }

      li:last-child {
        margin-bottom: 1.875rem;
      }
    }

    &__total {
      @extend .title, .title--meta;
      color: var(--tokens__colors__dark-grey);
      margin-top: 5px;
    }

    &__group {
      + .fabrics__filters__group {
        li:last-child {
          margin-bottom: 0;
        }
      }

      &__header {
        text-transform: uppercase;
        border-bottom: 1px solid var(--tokens__colors__light-grey);
        font-weight: 600;
        margin: 1rem 0;
        padding-bottom: 1rem;
        position: relative;

        button {
          width: 100%;

          &:hover {
            color: var(--tokens__colors__navy-blue);
          }
        }

        &::after {
          content: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.41 0.294998L6 4.875L10.59 0.294998L12 1.705L6 7.705L0 1.705L1.41 0.294998Z' fill='black'/%3E%3C/svg%3E%0A");
          position: absolute;
          right: 0;
          top: 0;
          transition: transform 0.15s ease-in;
          transform: rotate(0deg);
        }

        &--disabled {
          &::after {
            transform: rotate(-90deg);
          }
        }
      }
    }

    &__button {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 3px 0;
      color: #828282;
      position: relative;

      &:hover {
        color: var(--tokens__colors__navy-blue);
      }

      &::before {
        content: "";
        width: 30px;
        height: 30px;
        background: var(--tokens__colors__light-grey);
        border-radius: 100%;
        display: inline-block;
        margin-right: 0.625rem;
      }

      &--active {
        color: black;

        &::after {
          content: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4795 1.63998L4.31953 7.79998L1.51953 4.99998' stroke='%232D3436' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          position: absolute;
          left: 9px;
          top: 10px;
        }
      }

      h3 {
        font-weight: 400;
      }
    }
  }

  &__swatches {
    display: grid;
    grid-template-columns: var(--fabrics-grid-columns);
    grid-gap: 1.25rem;
    align-self: flex-start;
  }

  &__swatch {
    padding: 0.625rem;
    background: white;
    box-shadow: var(--tokens__box-shadow);

    h3 {
      @extend .title, .title--meta;
    }

    p {
      font-family: var(--tokens__font-family__secondary);
      color: var(--tokens__colors__dark-grey);
    }

    &__image {
      position: relative;
      padding-top: 100%;
      background: var(--tokens__colors__light-grey);
      width: 100%;
      height: auto;
      margin-bottom: 0.625rem;

      img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        // for Zoom
        cursor: zoom-in;
        transform-origin: center;
        transition: transform 0.16s ease-out, box-shadow 0.16s ease-out;
        image-rendering: -webkit-optimize-contrast;
      }

      .is-zoomed {
        cursor: zoom-out;
      }
    }
  }

  &__pagination {
    margin: 1.875rem auto 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    justify-self: flex-end;

    &__button {
      color: var(--tokens__colors__navy-blue);

      &--disabled {
        color: var(--tokens__colors__medium-grey);
      }
    }

    &__number {
      padding: 10px;
      font-size: 1.125rem;

      &--active {
        color: var(--tokens__colors__navy-blue);
      }
    }
  }
}

.zoom-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 90;
}
