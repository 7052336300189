.main {
    flex: 1 0 auto;

    // Account for the overlapping image in footer quote section
    > section:last-child {
        padding-bottom: calc(
            var(--tokens__quote__offset) + var(--tokens__padding)
        );
    }
}
