/*
 * Special object used to wrap long running copy delivered by the CMS
 *
 * Traditional typographic elements like ul gain automatic styling
 * Neighbors get pleasant margins to allow for easy reading.
 * Headings get automatic styling based on the .title class.
 */

:root {
  --wysiwyg-text__font-size: 1rem;
  --wysiwyg-text__list-gutter: 0.6rem;
  --wysiwyg-text__list__margin-left: 3rem;

  @include MQ-Above(small-medium) {
    --wysiwyg-text__list-gutter: 0.8rem;
  }

  @include MQ-Above(large) {
    --wysiwyg-text__list-gutter: 1rem;
  }
}

.wysiwyg-text {
  font-size: var(--wysiwyg-text__font-size);

  // Universal spacer
  // All spacing is a mutiple of the font-size, so that it scales as the base font-size scales. Can't use `em` because some components have different font sizes which would affect spacing unequally
  > * + * {
    // stylelint-disable-line
    margin-top: calc(var(--wysiwyg-text__font-size) * 1.25);
  }

  /*
     * Headings
     * Automatically add title classes, and tweak to work well as a set
     */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @extend .title;

    // Before headings, add more space.
    &:not(:first-child) {
      margin-top: 2rem;
    }

    margin-bottom: 0.625rem;
  }

  h1 {
    @extend .title--primary;
  }

  h2 {
    @extend .title--secondary;
  }

  h3 {
    @extend .title--tertiary;
  }

  h4 {
    @extend .title--quaternary;
  }

  h5 {
    @extend .title--meta;
  }

  h6 {
    @extend .title--micro;
  }

  /*
     * Styling for lists, which are meant to be lists of content in this case rather than being used just for semantic meaning
     */

  ul,
  ol {
    display: grid;
    grid-gap: var(--wysiwyg-text__list-gutter);
    margin-left: var(--wysiwyg-text__list__margin-left);
    line-height: 1.6;
    font-size: 0.9em;
    color: var(--tokens__colors__black--faded-60);

    li {
      display: list-item;

      > ul,
      > ol {
        --wysiwyg-text__list__margin-left: 2rem;
      }
    }
  }

  ul > li {
    list-style-type: disc;
  }

  ol > li {
    list-style-type: decimal;
  }

  blockquote {
    @extend .title;
    @extend .title--secondary;

    --title__color: var(--tokens__colors__blue);

    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    &::before {
      content: "“";
    }

    &::after {
      content: "”";
    }
  }

  /*
     * Special margins for unique combinations
     */

  > * + img,
  > * + figure,
  > img + *,
  > figure + * {
    margin-top: calc(var(--wysiwyg-text__font-size) * 2.5);
  }

  > a img,
  > a figure,
  > p img,
  > p figure {
    margin-top: calc(var(--wysiwyg-text__font-size) * 1.5);
    margin-bottom: calc(var(--wysiwyg-text__font-size) * 1.5);
  }

  * + hr,
  hr + * {
    margin-top: calc(var(--wysiwyg-text__font-size) * 3);
  }

  p {
    color: var(--tokens__colors__dark-grey);
  }

  a {
    color: var(--tokens__colors__navy-blue);
    word-break: break-all;
  }

  /*
     * Bard field support
     */

  .text {
    > * + * {
      margin-top: calc(var(--wysiwyg-text__font-size) * 1.25);
    }
  }
}
