.location {
    font-family: var(--tokens__font-family__secondary);
    color: var(--tokens__colors__dark-grey);
    display: flex;
    align-items: center;

    &:before {
        content: url("data:image/svg+xml,%3Csvg width='12' height='18' viewBox='0 0 12 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.0013 0.666672C2.7763 0.666672 0.167969 3.27501 0.167969 6.50001C0.167969 10.875 6.0013 17.3333 6.0013 17.3333C6.0013 17.3333 11.8346 10.875 11.8346 6.50001C11.8346 3.27501 9.2263 0.666672 6.0013 0.666672ZM1.83464 6.50001C1.83464 4.20001 3.7013 2.33334 6.0013 2.33334C8.3013 2.33334 10.168 4.20001 10.168 6.50001C10.168 8.90001 7.76797 12.4917 6.0013 14.7333C4.26797 12.5083 1.83464 8.875 1.83464 6.50001ZM3.91797 6.5C3.91797 5.34941 4.85071 4.41667 6.0013 4.41667C6.74561 4.41667 7.43337 4.81375 7.80552 5.45834C8.17767 6.10292 8.17767 6.89709 7.80552 7.54167C7.43337 8.18626 6.74561 8.58334 6.0013 8.58334C4.85071 8.58334 3.91797 7.6506 3.91797 6.5Z' fill='%23666666'/%3E%3C/svg%3E");
        display: inline-flex;
        margin: 0 8px -7px 0;
    }
}

button.location {
    transition: opacity 800ms ease-in;

    &:hover {
        opacity: 0.5;
    }
}
