.contact {
    &__info {
        h2 {
            @extend .title, .title--secondary, .title--uppercase;
            margin-bottom: 1.875rem;
        }

        h3 {
            @extend .title, .title--tertiary;
        }

        h3,
        h4 {
            margin: 1.25rem 0 0.4375rem 0;
        }

        a {
            &:hover {
                text-decoration: underline;
            }
        }

        &__map {
            margin-top: 1.875rem;
            position: relative;
            padding-top: 45%;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        &__address {
            color: var(--tokens__colors__dark-grey);
            font-family: var(--tokens__font-family__secondary);
            display: flex;
            align-items: center;
            margin-top: 5px;

            svg {
                width: auto;
                margin-right: 5px;
            }
        }
    }
}
