.t__article {
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 40vw;
    height: 21rem;
    background: var(--tokens__colors__light-blue);
    z-index: -2;
  }

  &__inner {
    @include Container(
      small,
      $padding_desktop: 2.5rem 0,
      $padding_mobile: 1.5rem 0
    );
  }

  &__content {
    background: white;
    padding: var(--tokens__padding);
  }

  &__featured-image {
    margin-bottom: 2rem;
  }

  &__breadcrumb {
    font-family: var(--tokens__font-family__secondary);
    background: white;
    box-shadow: var(--tokens__box-shadow);
    padding: 5px 10px;
    margin: 0 0 1rem 0;

    &:hover {
      color: var(--tokens__colors__navy-blue);
    }
  }

  &__header {
    margin-bottom: 2rem;

    h2 {
      @extend .title, .title--quaternary;
      margin-top: 0.625rem;
    }
  }

  &__media {
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      width: 50vw;
      height: 21rem;
      background: var(--tokens__colors__light-blue);
    }
  }

  &__fact {
    position: relative;
    margin: 2rem 0;
    display: block;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: -10%;
      width: 80vw;
      height: 100%;
      background: var(--tokens__colors__navy-blue);
    }
  }
}
