.fact {
  background: var(--tokens__colors__navy-blue);
  color: white;

  &__inner {
    position: relative;
    z-index: 2;
    padding: 2rem;
  }

  header {
    text-transform: uppercase;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    svg {
      width: auto;
      margin-right: 0.625rem;
    }
  }

  p {
    font-family: var(--tokens__font-family__secondary);
    font-style: italic;
    font-size: 1.2rem;
  }
}
