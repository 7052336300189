:root {
  --banner-background-color: black;
  --banner-font-color: white;
  --banner-text-align: center;
  --banner-display: grid;
}

.site-banner {
  background: var(--banner-background-color);
  color: var(--banner-font-color);
  text-align:var(--banner-text-align);
  display: var(--banner-display);
  padding: 0.5rem;
  text-transform: uppercase;
    font-size: 0.875rem;

  .no-touchevents &:hover {
    opacity: 0.8;
  }

  &[aria-hidden="true"] {
    display: none;  
  }
}

