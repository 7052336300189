.product-listing {
  position: relative;

  &:after {
    content: "";
    height: 40%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--tokens__colors__light-blue);
    z-index: -1;
  }

  &__inner {
    @include Container();
  }

  &__products {
    @include Grid(3, $repeat: true);
    margin: 1.875rem 0 0 0;
    min-height: 537px;
  }

  &__product {
    background: white;

    &:hover .product-listing__product__inner {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    }

    &__inner {
      background: white;
      box-shadow: 0px 2px 15px 1px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.15s ease-in;
      text-align: center;
      width: 80%;
      margin: -50px auto 50px auto;
      position: relative;
      z-index: 1;
      padding: 1.875rem;

      h3 {
        @extend .title, .title--quaternary;
        margin-bottom: 1rem;
      }
    }

    &__image {
      @extend .inset-border;

      img {
        width: 100%;
        height: auto;
      }
    }

    &__footer {
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 30%;
        height: 1px;
        border-top: 1px dotted black;
        margin: 1.25rem auto;
      }

      .cta {
        font-weight: 500;
      }
    }

    &__price {
      @extend .title, .title--meta, .title--font-family--secondary;
      font-style: italic;

      span {
        @extend .title, .title--quaternary;
        --title__color: var(--tokens__colors__navy-blue);
        --title__font-weight: 600;
        font-style: normal;
        margin-left: 0.4375rem;
      }
    }
  }
}
