:root {
  --t__home__link__text__padding: 1.8rem;
  --t__home__link__image__offset: 50px;

  @include MQ-Above(medium) {
    --t__home__link__offset: 70px;
    --t__home__link__image__width: 100%;
    --t__home__link__image__offset: 0;
  }
}

.t__home {
  &::after {
    content: "";
    position: absolute;
    display: block;
    background: var(--tokens__colors__light-blue);
    z-index: -1;
    width: 100%;
    height: 50%;
    right: 0;
    bottom: 0;
  }

  @include MQ-Above(medium) {
    &::after {
      width: 50%;
      height: 100%;
      right: 0;
    }
  }

  &__inner {
    @include Container();
    height: 100%;
    min-height: calc(100vh - var(--tokens__navigation__height));
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &__content {
    @include Grid(2fr 4fr);
    height: 100%;
    align-items: center;

    header {
      h1 {
        @extend .title, .title--primary, .title--uppercase;
        margin-bottom: 0.5rem;
      }

      p {
        font-family: var(--tokens__font-family__secondary);
        font-style: italic;
        font-size: 1.125rem;
      }
    }
  }

  &__links {
    @include Grid(1fr 1fr, $gap_desktop: 0, $gap_mobile: 0);
    margin-top: var(--t__home__link__image__offset);

    @include MQ-Above(medium) {
      transform: translate3d(
        var(--t__home__link__offset),
        calc(var(--t__home__link__offset) * -1 / 2),
        0
      );
    }

    &__link {
      position: relative;

      &--0 {
        .t__home__links__link__image {
          margin-left: auto;
        }
      }

      &--1 {
        position: relative;
        z-index: 1;

        .t__home__links__link__text {
          right: 0;
        }

        transform: translate3d(
          0,
          calc(var(--t__home__link__image__offset) * -1),
          0
        );

        @include MQ-Above(medium) {
          transform: translate3d(
            calc(var(--t__home__link__offset) * -1),
            var(--t__home__link__offset),
            0
          );
        }
      }

      &__image {
        @extend .inset-border;
        width: var(--t__home__link__image__width, 80%);

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      &:hover {
        .t__home__links__link__text {
          transform: translateY(-1rem);
          box-shadow: var(--tokens__box-shadow);
        }
      }

      &__text {
        background: white;
        padding: var(--t__home__link__text__padding);
        margin: calc(var(--t__home__link__text__padding) * -1) 0 0
          calc(var(--t__home__link__text__padding) * -1);
        position: relative;
        z-index: 1;
        width: 75%;
        box-shadow: 0 0 0 rgba(255, 255, 255, 0);
        transition: transform 0.15s ease-in, box-shadow 0.15s ease-in;

        @include MQ-Below(medium) {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        h2 {
          @extend .title, .title--quaternary, .title--uppercase;
          font-weight: 600;
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  #site-footer {
    display: none;
  }
}
