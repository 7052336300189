:root {
  --map__grid-columns: 1fr 1fr;
  --tokens__padding: 1.5rem;
}

.map {
  background: var(--tokens__colors__light-blue);

  @include MQ-Above(medium) {
    margin-top: 4rem;
  }

  .map__inner {
    @include Container();

    @include MQ-Above(medium) {
      @include Grid(2, $repeat: true);
      align-items: center;
    }
  }

  .map__figure {
    position: relative;
    padding-top: 93.85%;
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    @include MQ-Above(large) {
      left: -4rem;
      top: -8rem;
    }
  }
}
