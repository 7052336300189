:root {
  --layer__gutters__v: 4rem;
  --layer__gutters__h: 1rem;
  --layer__max-width: var(--tokens__max-width);

  @include MQ-Below(medium) {
    --layer__gutters__h: 2rem;
  }

  @include MQ-Above(medium) {
    --layer__gutters__v: 6rem;
    --layer__gutters__h: 4rem;
  }
}

.layer {
  background: var(--layer__background);
  margin: var(--layer__gutters__top, var(--layer__gutters__v))
    var(--layer__gutters__h)
    var(--layer__gutters__bottom, var(--layer__gutters__v));
  display: grid;
  grid-template-columns: minmax(
    0,
    var(--layer__max-width)
  ); // Create centered interior columns. Any children will become a grid child, so be careful not to put content as a direct child of a layer which doesn‘t want to be one.

  grid-gap: var(--layer__gutters__inner);
  justify-content: center;
  position: relative;

  /*
   * Backgrounds
   */

  &--background {
    // For solid background layers, use interior padding instead of exterior margin.
    // This will allow backgrounded layers to space themselves away from non-backgrounded ones.
    padding: var(--layer__gutters__top, var(--layer__gutters__v))
      var(--layer__gutters__h)
      var(--layer__gutters__bottom, var(--layer__gutters__v));
    margin: 0;

    &--black {
      --layer__background: black;
    }

    &--light-grey {
      --layer__background: var(--tokens__colors__light-grey);
    }

    &--dark-grey {
      --layer__background: var(--tokens__colors__dark-grey);
    }

    &--gradient {
      --layer__background: linear-gradient(
        -90deg,
        var(--tokens__colors__light-blue) 0%,
        var(--tokens__colors__navy-blue) 100%
      );
    }
  }

  /*
   * Width Adjustments
   */

  &--width-narrow {
    --layer__max-width: @Layer__Width--Narrow;
  }

  &--width-jumbo {
    --layer__max-width: @Breakpoint__Jumbo;
  }

  &--width-full {
    --layer__max-width: none;
  }

  &--max-width-none {
    --layer__max-width: 1fr;
  }

  /*
   * Gutter Adjustments
   */

  &--gutters-none {
    --layer__gutters__v: 0;
    --layer__gutters__h: 0;
  }

  &--gutters-v-none {
    --layer__gutters__v: 0;
  }

  &--gutters-v-small {
    --layer__gutters__v: 3rem;

    @include MQ-Above(medium) {
      --layer__gutters__v: 4rem;
    }
  }

  &--gutters-v-extra-small {
    --layer__gutters__v: 1rem;
  }

  &--gutters-top-none {
    --layer__gutters__top: 0;
  }

  &--gutters-top-small {
    --layer__gutters__top: 3rem;

    @include MQ-Above(medium) {
      --layer__gutters__top: 4rem;
    }
  }

  &--gutters-bottom-none {
    --layer__gutters__bottom: 0;
  }

  &--gutters-bottom-none-desktop {
    @include MQ-Above(medium) {
      --layer__gutters__bottom: 0;
    }
  }

  &--gutters-h-none {
    --layer__gutters__h: 0;
  }

  &--gutters-h-small {
    --layer__gutters__h: 1rem;

    @include MQ-Below(medium) {
      --layer__gutters__h: 2rem;
    }
  }

  /*
  * Modifiers
  */

  &--overflow-hidden {
    overflow: hidden;
  }
}
