.inset-border {
    position: relative;

    &:after {
        content: "";
        display: block;
        width: 90%;
        height: 90%;
        border: 1px solid white;
        position: absolute;
        top: calc(5% - 1px);
        left: calc(5% - 1px);
    }
}
