.product-guide {
  &__inner {
    @include Container();
    @include Grid(4fr 7fr);
  }

  &__nav {
    background: white;
    box-shadow: var(--tokens__box-shadow);
    padding: 2rem;
    align-self: flex-start;
    width: 90%;
    position: sticky;
    top: calc(var(--tokens__padding) + var(--site-header__height));
    color: var(--tokens__colors__dark-grey);

    li {
      text-transform: uppercase;

      + li {
        margin-top: 1.875rem;
      }
    }

    &--active {
      color: var(--tokens__colors__navy-blue);
    }
  }

  &__content {
    @extend .wysiwyg-text;
    display: grid;
    grid-gap: 1.875rem;

    h2 {
      margin-bottom: 0.625rem;
      text-transform: uppercase;
    }

    &__section {
      &:not(:first-of-type)::before {
        content: "";
        display: block;
        width: 5.625rem;
        margin: 0 0 1.875rem 0;
        height: 1px;
        background: black;
      }

      &:focus {
        outline: none;
      }
    }
  }
}
