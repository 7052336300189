$site-header__breakpoint: 1260px;
$site-header__breakpoint--max: $site-header__breakpoint - 1px;

:root {
  // --site-header__height: 3.75rem;
  --site-header__height: 5rem;

  @include MQ-Above(medium) {
    // --site-header__height: 6.25rem;
    --site-header__height: 8.25rem;
    // --site-header__logo__width: 9rem;
    // 150-years logo:
    --site-header__logo__width: 136px;
  }
}

.site-header {
  background: white;
  z-index: 5;
  color: var(--tokens__colors__dark-grey);
  height: var(--site-header__height);
  width: 100%;
  position: fixed;
  top: 0;
  transform-origin: top center;
  transition: height 0.15s ease-in;

  @include MQ-Below(medium) {
    border-bottom: 1px solid var(--tokens__colors__light-grey);
  }

  // Custom breakpoint for when the nav starts to get weird
  &__desktop {
    height: inherit; // Inherited heights aid megamenu hovering

    @media (max-width: $site-header__breakpoint--max) {
      display: none;
    }
  }

  &--sticky {
    @include MQ-Above(medium) {
      // --site-header__height: 70px;
      --site-header__height: 100px;

      border-bottom: 1px solid var(--tokens__colors__light-grey);

      .site-header__inner {
        &__logo {
          width: 7rem;
        }
      }
    }
  }

  &__inner {
    @include Container(
      $padding_desktop: 0 2.5rem,
      $padding_mobile: 0.625rem 1.5rem
    );

    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;

    &__logo {
      color: black;
      width: var(--site-header__logo__width, 6rem);
      transition: opacity 0.15s ease-in, width 0.15s ease-in;

      &:hover {
        opacity: 0.6;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  nav {
    height: inherit;
    margin-left: 1rem;
  }

  &__main-nav {
    height: inherit;
    display: flex;
    align-items: center;
    gap: 0.625rem;

    > li {
      display: flex;
      align-items: center;
      position: relative;

      &:hover,
      &:focus-within {
        --site-header__main-nav__primary-link__color: black;
        --site-header__main-nav__primary-link__border-color: currentColor;
        --site-header__megamenu-trigger__active-helper-opacity: 1;

        > .site-header__subnav {
          visibility: visible;
          opacity: 1;
        }
      }

      // Selector setup keeps focus outline from shifting due to hover intent helper
      &:hover:not(:focus-within) {
        --site-header__megamenu-trigger__hover-intent-helper-display: block;
      }
    }

    // All Links except contact button
    a:not(.site-header__main-nav__contact) {
      text-decoration: none;
      transition: color 0.25s ease-in;
      text-transform: uppercase;
      font-size: 0.875rem;
    }

    // Primary nav links
    > li
      > a:not(.site-header__main-nav__contact, .site-header__main-nav__phone) {
      padding: 0.75rem 1rem;
      border: 1px solid transparent;
      transition-property: color, background-color, border;
      color: var(--site-header__main-nav__primary-link__color);
      background-color: var(
        --site-header__main-nav__primary-link__background-color
      );
      border: 1px solid
        var(--site-header__main-nav__primary-link__border-color, transparent);

      // Specific megamenu trigger styles
      &.site-header__megamenu-trigger {
        position: relative;

        // ::before used as hover intent helper
        // - CSS (un)hover intent is awkward with current transition and hide/show setup, so this is a stopgap
        // - Adds invisible trapezoid shape to extend hover area below actively hovered item. Add background color to debug
        &::before {
          content: "";
          display: var(
            --site-header__megamenu-trigger__hover-intent-helper-display,
            none
          );
          position: absolute;
          top: 50%;
          left: 50%;
          height: calc(0.5 * var(--site-header__height));
          width: calc(100% + 12rem);
          transform: translateX(-50%);
          clip-path: polygon(6rem 0, calc(100% - 6rem) 0, 100% 100%, 0 100%);
          z-index: 1;
        }

        // Helper inidcator for megamenu hover
        &::after {
          content: "";
          display: block;
          position: absolute;
          background: var(--tokens__colors__sky-blue);
          border-radius: 100%;
          top: calc(100% + 0.375rem);
          left: 50%;
          height: 0.375rem;
          width: 0.375rem;
          transform: translateX(-50%);
          opacity: var(
            --site-header__megamenu-trigger__active-helper-opacity,
            0
          );
          transition: opacity 0.25s ease-in;
        }
      }

      &[aria-current="page"] {
        --site-header__main-nav__primary-link__border-color: currentColor;
      }

      // NOTE: Primary nav link hover styles handled by parent <li> hover
      //       This persists hover styles when when hover moves from link to any subnav elements
    }

    // Specific phone link styles
    &__phone {
      display: flex;
      align-items: center;
      padding: 0.25rem 1rem;

      svg {
        margin-right: 5px;
        width: auto;
      }

      &:hover {
        color: var(--tokens__colors__navy-blue);
      }
    }
  }

  &__subnav {
    display: block;
    position: absolute;
    transition: opacity 0.15s ease-in;
    top: 100%;
    left: 0;
    background: white;
    padding: 1rem;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
    min-width: 10rem;

    li {
      font-size: 0.875rem;
      white-space: nowrap;

      a {
        padding: 0.625rem 0;
        width: 100%;

        &[aria-current="page"],
        &:hover {
          color: var(--tokens__colors__navy-blue);
        }
      }
    }

    &[aria-hidden="true"] {
      visibility: hidden;
      opacity: 0;
    }

    &[aria-hidden="false"] {
      visibility: visible;
      opacity: 1;
    }
  }

  &__subnav--megamenu {
    // Overrides and resets
    position: fixed;
    width: 100%;
    top: calc(
      var(--site-header__height) - 1px
    ); // Account for border of .site-nav--sticky
    left: 0;
    padding: 2.5rem 0;
    border-top: 1px solid var(--tokens__colors__light-grey);
    transition: opacity 0.15s ease-in, top 0.15s ease-in; // Top transition matches main nav transition

    &__inner {
      @include Container(
        $padding_desktop: 0 2.5rem,
        $padding_mobile: 0.625rem 1.5rem
      );

      width: 100%;
      height: 100%;
      display: grid;
      gap: 1.875rem 1.25rem;
      grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    }

    // Megamenu section partial
    .site-nav-megamenu-items {
      header {
        --title__color: black;

        margin-bottom: 0.625rem;
      }
    }
  }
}
