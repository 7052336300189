:root {
  /* Columns */
  --grid__columns--two: 1fr;
  --grid__columns--three: 1fr;
  --grid__columns--four: repeat(2, 1fr);
  --grid__columns__two-six: 1fr;

  @include MQ-Between(small, large) {
    --grid-gap: var(--tokens__gap);
    --grid__columns--three: repeat(2, 1fr);
    --grid__columns--four: repeat(3, 1fr);
  }

  @include MQ-Above(medium) {
    --grid-gap: var(--tokens__gap);
    --grid-gap-large: 2rem;
    --grid__columns--two: repeat(2, 1fr);
    --grid__columns--three: repeat(3, 1fr);
    --grid__columns--four: repeat(4, 1fr);
    --grid__columns__two-six: 2fr 6fr;
  }
}

.grid {
  display: grid;
  grid-gap: var(--grid-gap, 2rem);

  // Alignment
  &--align-center {
    align-items: center;
  }

  // Spacing
  &--gap-large {
    grid-gap: var(--grid-gap-large, 2rem);
  }
}

// Columns
.grid-columns--four {
  grid-template-columns: var(--grid__columns--four);
}

.grid-columns--three {
  grid-template-columns: var(--grid__columns--three);
}

.grid-columns--two {
  grid-template-columns: var(--grid__columns--two);
}

.grid-columns--two-six {
  grid-template-columns: var(--grid__columns__two-six);
}
