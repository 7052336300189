:root {
  --article__header__margin-bottom: 1.5rem;
  --article__footer__margin-top: 1.5rem;
}

article {
  > header {
    margin-bottom: var(--article__header__margin-bottom);
  }

  > footer {
    margin-top: var(--article__footer__margin-top);
  }
}
