.dealer-resources {
  &__inner {
    @include Container();
    @include Grid(7fr 4fr, $gap_desktop: 6.25rem, $gap_mobile: 1.875rem);
    align-items: flex-start;

    h2 {
      @extend .title, .title--secondary, .title--uppercase;
      margin-bottom: 1.875rem;
    }
  }

  &__sidebar {
    display: grid;
    grid-gap: 3rem;
  }

  &__downloads {
    ul {
      padding-right: 1.25rem;

      li {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        padding: 1.25rem 0 1.25rem 1.25rem;
        border: 1px solid var(--tokens__colors__light-grey);

        @include MQ-Above(medium) {
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
        }

        header {
          @extend .title, .title--quaternary;
          font-weight: 600;
        }

        footer {
          margin: 0.625rem -1.25rem 0 0;
          align-self: flex-end;
          display: flex;
          align-items: center;

          @include MQ-Above(medium) {
            margin: 0 -1.25rem 0 0;
          }

          a {
            white-space: nowrap;
          }

          a + a {
            margin-left: 1.875rem;
          }
        }

        + li {
          margin-top: 1.25rem;
        }
      }
    }

    &__link {
      color: var(--tokens__colors__navy-blue);
      font-family: var(--tokens__font-family__secondary);
      font-style: italic;
      font-size: 1.125rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__form {
    form {
      display: grid;
      grid-gap: 1rem;
    }
  }
}
