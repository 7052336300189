.columns {
  display: flex;
  flex-direction: column;
  margin: -30px;

  > li {
    padding: 30px;
  }

  @include MQ-Above(medium) {
    flex-direction: row;
  }
}

.columns--two {
  @include MQ-Above(medium) {
    > li {
      width: 50%;
    }
  }
}

.columns--three {
  @include MQ-Above(medium) {
    > li {
      width: 33.33%;
    }
  }
}

.columns--five {
  @include MQ-Above(medium) {
    > li {
      width: 20%;
    }
  }
}
