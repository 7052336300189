:root {
    --asymetric-grid--offset: 3.75rem;
}

.asymetric-grid {
    @include MQ-Above(medium) {
        &--ltr {
            margin: calc(var(--asymetric-grid--offset) * 2) 0 1.25rem 0;

            li {
                &:nth-child(3n + 1) {
                    transform: translateY(
                        calc(var(--asymetric-grid--offset) * -1)
                    );
                }

                &:nth-child(3n) {
                    transform: translateY(var(--asymetric-grid--offset));
                }
            }
        }

        &--rtl {
            margin: calc(var(--asymetric-grid--offset) * 2) 0 1.25rem 0;

            li {
                &:nth-child(3n + 1) {
                    transform: translateY(var(--asymetric-grid--offset));
                }

                &:nth-child(3n) {
                    transform: translateY(
                        calc(var(--asymetric-grid--offset) * -1)
                    );
                }
            }
        }
    }
}
