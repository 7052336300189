// Chevron
.icon--chevron::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  right: 5px;
  top: calc(50% - 10px);
}

// Arrow Right
.icon--arrow-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
}

.icon--arrow-right::after,
.icon--arrow-right::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 3px;
}

.icon--arrow-right::after {
  width: 8px;
  height: 8px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  bottom: 7px;
}

.icon--arrow-right::before {
  width: 16px;
  height: 2px;
  bottom: 10px;
  background: currentColor;
}

// Arrow Left
.icon--arrow-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
}

.icon--arrow-left::after,
.icon--arrow-left::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
}

.icon--arrow-left::after {
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  bottom: 7px;
}

.icon--arrow-left::before {
  width: 16px;
  height: 2px;
  bottom: 10px;
  background: currentColor;
}

// Play
.icon--play {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 20px;
}

.icon--play::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid;
  top: 4px;
  left: 7px;
}

// Pause
.icon--pause {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 22px;
}

.icon--pause::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  left: 6px;
  top: 6px;
  border-left: 2px solid;
  border-right: 2px solid;
}
