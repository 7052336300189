:root {
    --sample-projects-list__grid-columns: 1fr;
    --sample-projects-list__grid-gap: 1rem;

    @include MQ-Above(small-medium) {
        --sample-projects-list__grid-columns: repeat(2, 1fr);
        --sample-projects-list__grid-gap: 40px;
    }

    @include MQ-Above(medium) {
        --sample-projects-list__grid-columns: repeat(3, 1fr);
    }
}

.sample-projects-list {
    &__projects {
        display: grid;
        grid-template-columns: var(--sample-projects-list__grid-columns);
        grid-gap: var(--sample-projects-list__grid-gap);
        align-items: start;
        padding: var(--sample-projects-list__grid-gap) 0;
    }

    &__project {
        &:hover .sample-projects-list__project__inner {
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
        }

        &__inner {
            background: white;
            box-shadow: 0px 2px 15px 1px rgba(0, 0, 0, 0.1);
            transition: box-shadow 0.15s ease-in;
            text-align: center;
            width: 80%;
            margin: -50px auto 50px auto;
            position: relative;
            z-index: 1;
            padding: 1.875rem 1.375rem;

            h3 {
                @extend .title, .title--quaternary;
                margin-bottom: 1rem;
            }

            .location {
                justify-content: center;
            }
        }

        &__image {
            @extend .inset-border;

            img {
                width: 100%; // Ensure images fill the frame
            }
        }
    }
}
