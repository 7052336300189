:root {
  --site-mobile-nav__subnav-trigger__width: 4rem;
  --site-mobile-nav__subnav--megamenu__columns: 1;

  @media (min-width: 375px) {
    --site-mobile-nav__subnav--megamenu__columns: 2;
  }

  @include MQ-Above(small-medium) {
    --site-mobile-nav__subnav--megamenu__columns: 3;
  }
}

.site-mobile-nav {
  background: white;
  z-index: 100;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  height: calc(
    100vh - var(--site-header__height) + 1px
  ); // Add 1px to account for border top
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--tokens__colors__light-grey);

  @media (min-width: $site-header__breakpoint) {
    display: none;
  }

  &-trigger {
    @media (min-width: $site-header__breakpoint) {
      display: none;
    }
  }

  &__phone {
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
      width: auto;
    }
  }

  nav {
    margin: 0;

    .site-header__main-nav {
      flex-direction: column;
    }

    > ul {
      > li {
        + li:not(:last-of-type) {
          border-top: 1px solid var(--tokens__colors__light-grey);
        }

        // Main links
        > a:not(.site-mobile-nav__contact),
        > button {
          padding: 1rem 1.5rem 1rem 1rem;
          color: black;

          // Exceptions
          &:not(.site-mobile-nav__phone) {
            font-size: 1.25rem;
            font-weight: 500;
          }
        }

        // All links, including subnav
        a,
        button {
          width: 100%;
        }

        a:not(.site-mobile-nav__contact),
        button {
          text-decoration: none;
          transition: color 0.25s ease-in;

          &[aria-current="page"],
          &:hover {
            color: var(--tokens__colors__navy-blue);
          }
        }
      }
    }
  }

  &__subnav {
    padding: 1rem;
    border-top: 1px solid var(--tokens__colors__light-grey);

    li {
      a {
        font-size: 13px;
        text-transform: uppercase;
        padding: 0.625rem 0;
      }
    }

    // Subnav trigger button
    &-trigger {
      display: flex;
      align-items: center;
      gap: 1rem;

      // CSS only dropdown icon
      &__icon {
        margin-left: auto;
        height: 0.625rem;
        width: 0.625rem;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 2px 2px 0
          var(--site-mobile-nav__subnav-trigger__icon__color, black);
        transition: all 0.15s ease-in;
        transition-property: transform, box-shadow;
        transform: var(
          --site-mobile-nav__subnav-trigger__icon__transform,
          translate(-25%, -25%) rotate(45deg)
        );
        transform-origin: 75% 75%;
      }

      &:hover {
        --site-mobile-nav__subnav-trigger__icon__color: var(
          --tokens__colors__navy-blue
        );
      }

      &[aria-expanded="true"] {
        --site-mobile-nav__subnav-trigger__icon__transform: translate(
            -25%,
            -25%
          )
          rotate(225deg);
      }
    }

    &[aria-hidden="true"] {
      visibility: hidden;
      opacity: 0;
    }

    &[aria-hidden="false"] {
      visibility: visible;
      opacity: 1;
    }
  }

  &__subnav--megamenu {
    // Overrides and resets
    display: grid;
    gap: 1.875rem 1.25rem;
    grid-template-columns: repeat(
      var(--site-mobile-nav__subnav--megamenu__columns),
      1fr
    );

    // Inner instance of subnav section's link
    .site-nav-megamenu-items--main-link {
      grid-column: 1 / -1; // Span all columns
    }

    // Megamenu section partial
    .site-nav-megamenu-items {
      header {
        --title__font-size: 1rem;
        --title__color: black;

        margin-bottom: 0.625rem;
      }
    }
  }

  &[aria-hidden="true"] {
    display: none;
  }
}
