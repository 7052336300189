.t__blog-index {
  background: var(--tokens__colors__faint-grey);
  position: relative;

  &__inner {
    @include Container();
  }

  &__pagination {
    margin: 2rem auto;
    text-align: center;

    a {
      margin: 0 1rem;
    }
  }

  .cards__list {
    &__card {
      transition: border 0.3s ease-in;
      border: 1px solid var(--tokens__colors__light-grey);

      h3 {
        font-family: var(--tokens__font-family__secondary);
        font-weight: 400;
      }

      &:hover {
        border: 1px solid var(--tokens__colors__navy-blue);
      }
    }
  }
}
