:root {
  --link-list__gutter: 0.625rem;
  --link-list__gutter__top: calc(0.5 * var(--link-list__gutter));
  --link-list__display: flex;
  --link-list__width: 100vw;

  @include MQ-Above(medium) {
    --link-list__justify-content: center;
    --link-list__width: auto;
    --link-list__gutter: 1rem;
  }

  @include MQ-Above(large) {
    --link-list__justify-content: center;
  }
}

ul.link-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: var(--link-list__justify-content);
  padding: 0 0 1rem 0;

  @include MQ-Below(medium) {
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-coordinate: 0 0;
    scroll-snap-points-x: repeat(100%);
    scroll-snap-type: x proximity;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    width: 100vw;
    margin: 0 calc(var(--tokens__padding) * -1);
    position: relative;
    flex-wrap: nowrap;

    li {
      &:first-child:before {
        content: "";
        display: inline-flex;
        width: var(--tokens__padding);
      }

      &:last-child:after {
        content: "";
        display: inline-flex;
        width: var(--tokens__padding);
      }
    }
  }

  li {
    display: flex;
    padding: var(--link-list__gutter);
    flex: 0 0 auto;
    scroll-snap-align: center;
  }
}
