:root {
  @include MQ-Above(medium) {
    --configurator__preview__min-height: 365px;
  }
}

.configurator {
  &__inner {
    @include Container();
  }

  .header--section {
    @include Container($width: small, $padding_desktop: 0, $padding_mobile: 0);

    text-align: center;
    margin-bottom: 2rem;
  }

  &__product {
    @include Grid(
      $columns_desktop: 5fr 3fr,
      $columns_mobile: 1fr,
      $gap_desktop: 0
    );
    background: white;
    box-shadow: var(--tokens__box-shadow);

    &__options {
      padding: 2rem;

      > div {
        height: 100%;
      }

      header {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 2rem;

        @include MQ-Above(medium) {
          flex-direction: row;
          justify-content: space-between;
        }

        h3 {
          @extend .title, .title--tertiary;
          font-weight: 600;
        }

        p {
          font-family: var(--tokens__font-family__secondary);
          font-style: italic;
        }
      }
    }

    &__buttons {
      @include Grid(2, $repeat: true);

      &__button {
        padding: 1rem;
        border: 1px solid var(--tokens__colors__light-grey);
        transition: border 0.15s ease-in;
        display: flex;
        align-items: center;
        min-height: 5.125rem;
        position: relative;

        &:hover {
          border: 1px solid var(--tokens__colors__navy-blue);
        }

        h4 {
          @extend .title, .title--quaternary;
          font-weight: 600;
          margin-bottom: 0.5rem;
        }

        p {
          font-size: 14px;
          line-height: 1.2;
          color: var(--tokens__colors__dark-grey);
          padding-right: 30px;
        }

        &--selected {
          border: 1px solid var(--tokens__colors__navy-blue);

          &::after {
            content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='11' fill='%23005c99'/%3E%3Cpath d='M10.2426 16.3139L6 12.0712L7.41421 10.657L10.2426 13.4855L15.8995 7.82861L17.3137 9.24283L10.2426 16.3139Z' fill='white'/%3E%3C/svg%3E%0A");
            display: block;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
          }
        }
      }

      &__icon {
        margin-right: 1rem;
      }
    }

    &__preview {
      padding: 2rem;
      background: var(--tokens__colors__light-blue);
      position: relative;
      min-height: var(--configurator__preview__min-height, 200px);

      &__images {
        width: 100%;
        position: relative;
        overflow: hidden;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        &__image {
          grid-column-start: 1;
          grid-row-start: 1;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: opacity 0.5s ease-in;

          &--active {
            opacity: 1;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      button {
        @extend .title, .title--tertiary;

        color: white;
        margin: 0 0 1.875rem 0;
        animation: jump 0.5s linear alternate infinite;
        z-index: 5;

        @include MQ-Above(medium) {
          position: absolute;
          top: -20px;
          right: 0;
          left: 0;
          bottom: -20px;
          top: auto;
          right: 0;
          margin: auto;
        }
      }
    }

    &__estimate {
      @include Grid(2, $repeat: true);
      align-items: center;
      justify-items: center;
      height: 100%;

      &__text {
        h4 {
          @extend .title, .title--quaternary;
          font-weight: 600;
        }

        h5 {
          @extend .title, .title--primary;
          color: var(--tokens__colors__navy-blue);
        }

        p {
          font-family: var(--tokens__font-family__secondary);
          font-size: 1.125rem;
          margin-top: 0.625rem;
        }
      }

      &__form {
        width: 100%;
      }
    }
  }

  &__controls {
    margin-top: 1rem;
  }
}

@keyframes jump {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 10%, 0);
  }
}
