.slider {
  ul {
    display: grid;

    li {
      grid-row: 1;
      grid-column: 1;

      &[aria-hidden="true"] {
        visibility: hidden; // Use visibility, not display, so that the slider height stays the same (calibrated to the tallest item)
      }

      h5 {
        margin-top: 1rem;
      }

      h5,
      h6 {
        --title__color: var(--tokens__colors__navy-blue);
      }
    }
  }

  nav {
    margin-top: 1.5rem;
  }

  button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid var(--tokens__colors__navy-blue);
    transition: background 0.3s ease-in;

    &[aria-selected="true"],
    &:hover {
      background: var(--tokens__colors__navy-blue);
    }
  }
}
