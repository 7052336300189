.site-footer {
    background: black;
    color: white;
    flex-shrink: 0;

    @include MQ-Below(medium) {
        text-align: center;
    }

    &__inner {
        @include Grid(3fr 2fr 2fr 4fr, $gap_mobile: 2rem);
        @include Container();
    }

    p,
    a {
        color: white;
    }

    a {
        &:hover,
        &[aria-current="page"] {
            text-decoration: underline;
        }
    }

    header {
        @extend .title;
        @extend .title--quaternary;

        margin-bottom: 1rem;
        font-weight: 600;

        
    }

    nav  {
       ul {
            display: grid;
            grid-gap: 0.625rem;
        }
        
        & + header {
            margin-top: 1rem;
        }
    }

    &__nav {
        li {
            a {
                color: var(--tokens__colors__light-grey);
            }
        }
    }

    &__about {
        &__logo {
            width: 11rem;
            margin-bottom: 1rem;

            svg {
                height: 100%;
                width: 100%;
            }
        }
    }

    &__social {
        display: flex;
        align-items: center;

        a {
            padding: 0.3125rem;
        }
    }

    &__footer {
        border-top: 1px solid var(--tokens__colors__dark-grey);

        &__inner {
            @include Container(
                $padding_desktop: 1rem 2.5rem,
                $padding_mobile: 1rem
            );
            @include Grid(5fr 7fr);
            @extend .title, .title--meta;

            align-items: center;
            color: var(--tokens__colors__medium-grey);
        }

        &__tagline {
            font-weight: 600;
            color: white;
        }

        &__copyright {
            text-align: right;
            color: var(--tokens__colors__medium-grey);
            font-family: var(--tokens__font-family__secondary);
        }
    }
}
