:root {
  @include MQ-Above(medium) {
    --testimonial__margin: 0 0 0 -6.25rem;
  }
}

.testimonial {
  position: relative;

  &__inner {
    @include Container(medium);

    position: relative;
    z-index: 1;
  }

  &__text {
    position: relative;
    margin: var(--testimonial__margin);
    z-index: 2;

    &__quote {
      --title__font-family: var(--tokens__font-family__secondary);
      --title__font-weight: 400;

      @extend .title, .title--tertiary;

      margin-bottom: 1rem;
      font-style: italic;
    }

    &__name {
      font-weight: 400;
      display: flex;
      align-items: center;

      &:before {
        content: "";
        width: 5rem;
        height: 1px;
        background: black;
        display: inline-block;
        margin-right: 0.625rem;
      }
    }

    &__title {
      --title__font-family: var(--tokens__font-family__secondary);

      @extend .title, .title--meta;

      margin-left: 5.625rem;
    }
  }

  &--with-media {
    @include Grid(2, $repeat: true);

    align-items: center;

    @include MQ-Below(medium) {
      .testimonial__media {
        order: 2;
      }
    }
  }
}
