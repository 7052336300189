:root {
    @include MQ-Above(medium) {
        --quote__inner__columns: repeat(12, 1fr);
        --quote__form__grid-area: 1 / 1 / 2 / 7;
        --quote__media__grid-area: 1 / 6 / 2 / 13;
        --quote__form__order: 1;
    }
}

.quote {
    color: white;
    position: relative;

    &__inner {
        @include Container($padding_desktop: 0, $padding_mobile: 0);
        display: grid;
        grid-template-columns: var(--quote__inner__columns);
        grid-template-rows: 1fr;
    }

    &__form {
        background: white;
        padding: 2rem;
        color: black;
        grid-area: var(--quote__form__grid-area);
        z-index: 1;
        order: var(--quote__form__order, 2);
        margin: var(--tokens__padding);

        header {
            h2 {
                @extend .title, .title--secondary, .title--uppercase;
            }

            p {
                color: var(--tokens__colors__dark-grey);
            }
        }

        &__group {
            margin-bottom: 1rem;

            &--columns-two {
                @include Grid(
                    2,
                    $repeat: true,
                    $gap_desktop: 1rem,
                    $gap_mobile: 1rem
                );
            }
        }
    }

    &__media {
        grid-area: var(--quote__media__grid-area);
        text-align: right;
        transform: translateY(calc(var(--tokens__quote__offset) * -1));
        position: relative;

        @include MQ-Below(medium) {
            padding-left: var(--tokens__padding);
            margin-bottom: -90px;
        }

        &__image {
            @extend .inset-border;

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        &__caption {
            margin: 5px 8px 0 auto;
            color: #bbbbbb;
            width: 85%;

            @include MQ-Below(medium) {
                position: absolute;
                bottom: 0;
                left: var(--tokens__padding);
                color: white;
                width: calc(100% - var(--tokens__padding));
                background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
                padding: 40px 40px 50px 40px;
            }
        }
    }

    &__bg {
        @extend .util__bg-fill;
        background: black;

        picture {
            opacity: 0.7;
        }
    }
}
