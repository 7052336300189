/* SCSS Variables */

$tokens__max-width: 1350px;

:root {
  /* Primary Colors */
  --tokens__colors__navy-blue: #005c99;
  --tokens__colors__sky-blue: #63c1ff;
  --tokens__colors__light-blue: #f8fbfe;

  /* Utility Colors */
  --tokens__colors__light-grey: #f5f5f5;
  --tokens__colors__medium-grey: #e1e1e1;
  --tokens__colors__dark-grey: #666666;
  --tokens__colors__white--faded-50: fade(white, 50%);
  --tokens__colors__black--faded-50: fade(black, 50%);

  /* Font Families */
  --tokens__font-family__primary: "Ubuntu", sans-serif;
  --tokens__font-family__secondary: "EB Garamond", serif;

  /* Transition */
  --tokens__transition: all 0.5s;

  /* Sizing */
  --tokens__max-width: $tokens__max-width;

  /* Shadows */
  --tokens__box-shadow: 0px 2px 15px 1px rgba(0, 0, 0, 0.1);

  /* Spacing */
  --tokens__padding: 1.5rem;
  --tokens__quote__offset: 2.25rem;
  --tokens__navigation__height: 60px;

  @include MQ-Above(medium) {
    --tokens__padding: 2.5rem;
    --tokens__quote__offset: 8.125rem;
    --tokens__navigation__height: 100px;
  }
}
