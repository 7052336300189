:root {
  --accordion__item__spacing: 1rem;
  --accordion__item__border-color: var(--tokens__colors__black--faded-50);
  --accordion__item__icon-width: 1rem;
  --accordion__item__icon-rotation: 0;
}

.accordion {
  &__item {
    > button {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding: var(--accordion__item__spacing) 0;
      border-top: 1px solid var(--accordion__item__border-color);

      dt {
        --title__font-size: 1rem;
        --title__font-weight: 800;
        --title__color: var(currentColor, currentColor);

        margin-right: calc(var(--accordion__item__icon-width) * 2);
        line-height: 1.2;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        right: 0;
        width: var(--accordion__item__icon-width);
        height: calc(var(--accordion__item__icon-width) / 10);
        background-color: var(--tokens__colors__navy-blue);
        transition: var(--tokens__transition);
        transform: rotate(var(--accordion__item__icon-rotation));
      }

      &::after {
        --accordion__item__icon-rotation: -90deg;
      }
    }

    &:last-child {
      border-bottom: 1px solid var(--accordion__item__border-color);
    }

    > dd {
      margin-right: calc(var(--accordion__item__icon-width) * 2);

      > p,
      ul {
        padding-bottom: var(--accordion__item__spacing);
        line-height: 1.6;
        opacity: 0.6;
      }

      > ul {
        li + li {
          margin-top: 0.5rem;
        }
      }
    }

    /* Closed state */
    &[aria-expanded="false"] > dd {
      display: none;
    }

    /* Open state */
    &[aria-expanded="true"] > button {
      &::before {
        --accordion__item__icon-rotation: -90deg;

        opacity: 0;
      }

      &::after {
        --accordion__item__icon-rotation: -180deg;
      }
    }
  }
}
