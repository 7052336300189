:root {
  --hero__max-width: 90vw;

  @include MQ-Above(medium) {
    --hero__content__padding: 3rem;
    --hero__min-height: 41rem;
    --hero__content__max-width: 31.25rem;
    --hero__max-width: 95vw;
    --hero__buttons__flex-direction: row;
    --hero__buttons__align-items: center;
    --hero__buttons__button-margin: 0 0 0 1rem;
    --hero__breadcrumbs__transform: translate3d(20px, 20px, 0);
    --hero__breadcrumbs__align: flex-end;
    --hero__breadcrumbs__margin: 0;
  }
}

.hero {
  min-height: var(--hero__min-height);
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 2rem;

  &::after {
    content: "";
    display: block;
    position: absolute;
    background: var(--tokens__colors__light-blue);
    z-index: -2;
    bottom: -24px;
    right: 55%;
    width: 48px;
    height: 48px;

    @include MQ-Above(medium) {
      bottom: -68px;
      right: 0;
      width: 136px;
      height: 136px;
    }
  }

  &__inner {
    @include Container($padding-desktop: 0, $padding-mobile: 0);

    width: 100%;
    display: flex;
    align-items: flex-start;
  }

  &__content {
    background: white;
    max-width: var(--hero__content__max-width);
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    h1 {
      @extend .title, .title--primary;

      margin-bottom: 0.625rem;
      text-transform: uppercase;
    }

    p {
      color: var(--tokens__colors__dark-grey);
    }

    em {
      font-style: normal;
      color: var(--tokens__colors__navy-blue);
    }

    u {
      text-decoration: none;
      font-family: var(--tokens__font-family__secondary);
      font-size: 1.4rem;
      margin-bottom: 0.3125rem;
      display: block;
      color: black;
    }

    &__text {
      padding: var(--hero__content__padding, 1.875rem);
    }

    &__breadcrumb {
      font-family: var(--tokens__font-family__secondary);
      background: white;
      box-shadow: var(--tokens__box-shadow);
      padding: 5px 10px;
      transform: var(--hero__breadcrumbs__transform);
      align-self: var(--hero__breadcrumbs__align, flex-start);
      margin: var(--hero__breadcrumbs__margin, 1.875rem 0 0 1.875rem);

      &:hover {
        color: var(--tokens__colors__navy-blue);
      }
    }
  }

  &__media {
    max-width: var(--hero__max-width);
    position: relative;

    @include MQ-Above(medium) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      overflow: hidden;
    }

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
    }
  }

  &__buttons {
    max-width: var(--hero__max-width);
    display: flex;
    justify-content: flex-end;
    align-items: var(--hero__buttons__align-items, flex-end);
    flex-direction: var(--hero__buttons__flex-direction, column);

    a + a {
      margin: var(--hero__buttons__button-margin, 1rem 0 0 0);
    }
  }

  &__price {
    position: absolute;
    bottom: 50px;
    right: 50px;
    background: black;
    color: white;
    width: 125px;
    height: 125px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 100%;
    padding: 20px;

    @include MQ-Below(medium) {
      left: 10px;
      bottom: 10px;
      transform: scale(0.8);
      transform-origin: bottom left;
    }

    &::before {
      content: "";
      width: 103px;
      height: 103px;
      border-radius: 100%;
      border: 1px dashed white;
      display: block;
      position: absolute;
      top: 10px;
      left: 10px;
    }

    &__value {
      font-size: 20px;
    }

    &__text {
      font-size: 15px;
      font-family: var(--tokens__font-family__secondary);
      font-style: italic;
      margin-bottom: 5px;
    }
  }

  &__warranty {
    svg {
      width: auto;
    }

    position: absolute;
    bottom: 50px;
    right: 50px;

    @include MQ-Below(medium) {
      left: 10px;
      bottom: 10px;
      transform: scale(0.7);
      transform-origin: bottom left;
    }
  }
}
