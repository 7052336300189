@keyframes slide-up {
  from {
    height: 4rem;
    color: white;
  }

  to {
    height: 0;
    color: fade(white, 0%);
  }
}

.site-alert {
  @extend .title, .title--meta;

  color: white;
  background: var(--tokens__colors__navy-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: slide-up 2s 4s;
  animation-fill-mode: both;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.35);
  overflow: hidden;

  &--error {
    background: #721c24;
  }
}
