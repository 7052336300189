:root {
    @include MQ-Above(medium) {
        --header--with-divider__margin: 2rem auto 3rem auto;
    }
}

.header {
    &--section {
        margin-bottom: 1.875rem;

        h2 {
            @extend .title, .title--secondary, .title--uppercase;
        }

        h2 + p {
            margin-top: 0.625rem;
        }

        p {
            color: var(--tokens__colors__dark-grey);
        }
    }

    &--text {
        margin-bottom: 1rem;
    }

    &--with-divider {
        text-align: center;

        &::after {
            content: "";
            display: block;
            margin: var(--header--with-divider__margin, 1rem auto);
            width: 5.625rem;
            height: 1px;
            background: black;
        }
    }
}
