:root {
  @include MQ-Above(medium) {
    --input__padding: 1rem;
  }
}

input[type="email"],
input[type="tel"],
input[type="text"],
input[type="password"],
input[type="number"],
textarea {
  display: block;
  width: 100%;
  border: none;
  padding: var(--input__padding, 0.6rem);
  background: white;
  font-size: inherit;
  font-family: var(--tokens__font-family__primary);
  border: 1px solid var(--tokens__colors__dark-grey);
  font-family: var(--tokens__font-family__primary);
}

input[type="email"],
input[type="tel"],
input[type="text"],
input[type="password"],
input[type="number"] {
  & ~ label {
    text-transform: uppercase;
  }
}

input[type="submit"] {
  border: none;
  appearance: none;
}
