:root {
  @include MQ-Above(medium) {
    --t__post__content__columns: 2fr 5fr 1fr;
    --t__post__about__columns: 1fr 2fr 4fr 1fr;
    --t__post__about__info__columns: ~"2 / 3";
    --t__post__about__bio__columns: ~"3 / 4";
    --t__post__related__blog-list__columns: 1fr 1fr;
  }
}

.t__post {
  .page-header {
    --layer__gutters__top: 2rem;
    --layer__gutters__bottom: 3rem;
    --page-header__columns: 1fr;
    --page-header__header__margin: 0;
  }

  &__content {
    display: grid;
    grid-template-columns: var(--t__post__content__columns, 1fr);
    grid-gap: 2.5rem;
  }

  &__meta {
    color: var(--tokens__colors__blue);

    &__author {
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-gap: 1rem;
      align-items: center;

      &__details {
        display: grid;
        grid-gap: 0.3125rem;
      }

      figure {
        border-radius: 50%;
        overflow: hidden;
      }

      &__name {
        font-weight: 600;
      }
    }

    &__categories {
      margin-top: 1rem;

      li {
        a {
          --category__color: var(--tokens__colors__blue);

          text-decoration: none;
          color: var(--category__color);
          border: 1px solid var(--category__color);
          display: inline-block;
          padding: 10px 15px;
          font-size: 0.875rem;
          transition: all 0.15s ease-in;

          &:hover,
          &.active {
            --category__color: black;

            opacity: 1;
          }
        }

        + li {
          margin-top: 5px;
        }
      }
    }
  }

  &__article {
    h1,
    h2 {
      @extend .title;
      @extend .title--tertiary;

      margin: 1.875rem 0 0.625rem 0;
    }

    h3,
    h4,
    h5,
    h6 {
      @extend .title;
      @extend .title--quaternary;
    }

    ul + p,
    ol + p {
      margin: 1rem 0 0.625rem 0;
    }

    // Hack for legacy WordPress html
    a[href*="/assets/img"] {
      margin: 1rem 0;
      width: 100%;
    }

    // Hack for legacy WordPress line breaks
    br {
      content: "";
      margin: 1rem;
      display: block;
    }

    img {
      width: 100%;
      height: auto;
    }

    ul,
    ol {
      li {
        line-height: 1.8;
        display: list-item;
        margin-left: 1rem;

        + li {
          margin-top: 0.625rem;
        }
      }
    }

    ul li {
      list-style: disc;
    }

    ol li {
      list-style: decimal;
    }

    &__caption {
      background: var(--tokens__colors__slate-blue);
      color: white;
      margin: 0 0 1.5rem 0;
      padding: 0.5rem 1rem;
    }

    &__excerpt {
      --title__font-weight: 400;

      margin-bottom: 2rem;
    }
  }

  &__about {
    display: grid;
    grid-template-columns: var(--t__post__about__columns, 1fr);
    grid-gap: 2rem;
    margin: 1rem auto 0 auto;
    padding: 2rem 0;
    border-top: 5px solid var(--tokens__colors__faint-grey);

    &__info {
      grid-column: var(--t__post__about__info__columns, auto);

      header {
        color: var(--tokens__colors__slate-blue);
        margin-bottom: 1rem;
      }
    }

    &__bio {
      grid-column: var(--t__post__about__bio__columns, auto);

      p {
        color: var(--tokens__colors__medium-grey);
      }
    }
  }
}
