.t__contact {
  &__inner {
    @include Container($padding_desktop: 0, $padding_mobile: 0);
    @include Grid(7fr 4fr, $gap_desktop: 5rem, $gap_mobile: 1.875rem);
    margin-bottom: var(--tokens__padding);
  }

  &__form,
  &__info {
    padding: var(--tokens__padding);
    margin: var(--tokens__padding) 0;
  }

  &__form {
    background: var(--tokens__colors__light-blue);
    align-self: flex-start;
  }
}
