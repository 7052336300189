:root {
  --primary-cta__color: black;
  --primary-cta__background-color: var(--tokens__colors__navy-blue);
}

.primary-cta {
  @extend .title;
  @extend .title--font-size-smaller;

  border-radius: 3.125rem;
  text-transform: uppercase;
  color: black;
  display: inline-block;
  text-decoration: none; // Override default anchor property
  white-space: nowrap;
  cursor: pointer;
  padding: 1rem 2rem;
  background-color: var(--primary-cta__background-color);
  transition: var(--tokens__transition);
  position: relative;
}
