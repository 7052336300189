select {
  appearance: none;
  box-shadow: none;
  border: 0 !important;
  color: #7d7d7d;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0;
  font-size: 16px;
  position: relative;
  width: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.19082 5.24014L12.2943 0.341704C12.4203 0.218657 12.6283 0.221587 12.7572 0.347563L13.6537 1.22354C13.7826 1.34952 13.7856 1.55459 13.6596 1.67764L7.42813 7.66006C7.36368 7.72452 7.27579 7.75381 7.19082 7.74795C7.10293 7.75088 7.01797 7.72159 6.95352 7.66006L0.722074 1.67764C0.596098 1.55459 0.599027 1.34952 0.727934 1.22354L1.62442 0.347563C1.75332 0.221587 1.96133 0.218657 2.08731 0.341704L7.19082 5.24014Z' fill='%23582C83'/%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.75rem top 50%, 0 0;
  background-size: 0.8rem auto, 100%;
  background-color: white;
}

select::-ms-expand {
  display: none;
}

.select-group {
  position: relative;
  flex: 1;
  width: 100%;

  select {
    position: relative;
  }

  &__clear {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 90%;
    background: linear-gradient(270deg, #fff 68.03%, rgba(255, 255, 255, 0) 100%);
    padding: 0 10px 0 20px;
    display: none;

    &:hover {
      &::after {
        transform: scale(1.1);
      }
    }

    &::after {
      content: "";
      display: block;
      z-index: 5;
      width: 22px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='23' viewBox='0 0 24 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12.1294' cy='11.5' r='11.5' fill='%23582C83'/%3E%3Cpath d='M6.62939 7.65222L8.28161 6L12.1294 9.84778L15.9772 6L17.6294 7.65222L13.7816 11.5L17.6294 15.3478L15.9772 17L12.1294 13.1522L8.28161 17L6.62939 15.3478L10.4772 11.5L6.62939 7.65222Z' fill='white'/%3E%3C/svg%3E%0A");
      transition: transform 0.1s ease-in;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--active {
      display: block;
    }
  }
}
