:root {
  --secondary-cta__font-weight: 800;
}

.secondary-cta {
  @extend .title;

  --title__font-weight: var(
    --secondary-cta__font-weight
  ); // Alias the custom property for clarity in updating elsewhere

  display: inline-flex;
  white-space: nowrap;
  cursor: pointer;
  opacity: 0.6; // Override default anchor property. NOTE: Should anchor link opacity be a custom property?

  &:hover {
    opacity: 1; // Override default anchor property. NOTE: Should anchor link opacity be a custom property?
  }
}
