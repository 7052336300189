:root {
  @include MQ-Above(medium) {
    --text-columns__columns: 2fr 6fr;
    --text-columns__columns__count: 2;
  }
}

.text-columns {
  &__inner {
    @include Container();
    @include Grid(2fr 6fr);
  }

  &__text {
    columns: var(--text-columns__columns__count, 1);
  }
}
