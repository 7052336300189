/* stylelint ignore */
//
//  RESTRICT LINES
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include Restrict-Lines(3) {}
// Clever method for restricting line length, from https://stackoverflow.com/questions/3922739/limit-text-length-to-n-lines-using-css
@mixin Restrict-Lines($LineCount) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $LineCount; // Number of lines to show
  align-self: flex-start; // Fixes ellipsis when used inside a flex container (otherwise it gains 100% height)
}

@mixin Grid(
  $columns_desktop,
  $columns_mobile: 1fr,
  $gap_desktop: 2.5rem,
  $gap_mobile: 1rem,
  $repeat: false
) {
  display: grid;
  grid-gap: $gap_mobile;
  grid-template-columns: $columns_mobile;

  @include MQ-Above(medium) {
    grid-gap: $gap_desktop;
    /* stylelint-disable */
    @if $repeat {
      grid-template-columns: repeat($columns_desktop, 1fr);
    } @else {
      grid-template-columns: $columns_desktop;
    }
    /* stylelint-enable */
  }
}

@mixin Container(
  $width: $tokens__max-width,
  $padding_desktop: 4rem 2.5rem,
  $padding_mobile: 1.5rem
) {
  /* stylelint-disable */
  @if $width == full {
    max-width: "none";
  } @else if $width == medium {
    max-width: 1000px;
  } @else if $width == small {
    max-width: 600px;
  } @else {
    max-width: $width;
  }
  /* stylelint-enable */

  width: 100%;
  margin: auto;
  padding: $padding_mobile;

  @include MQ-Above(medium) {
    padding: $padding_desktop;
  }
}
