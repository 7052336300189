:root {
    --text_with_media__secondary__offset: 1rem;

    @include MQ-Above(medium) {
        --text_with_media__left__text-padding: 0 0 0 1rem;
        --text_with_media__right__text-padding: 0 1rem 0 0;
        --text_with_media__right__order: 2;
        --text_with_media__secondary__offset: 1.875rem;
    }
}

.text_with_media {
    padding: var(--tokens__padding) 0;

    h2 {
        text-transform: uppercase;
    }

    &--2 {
        padding-bottom: var(--text_with_media__secondary__offset);
    }

    &--text {
        .text_with_media__inner {
            @include Grid(2, $repeat: true);
            @include Container($padding_desktop: 0, $padding_mobile: 0);
            align-items: center;
        }
    }

    &__images {
        position: relative;

        picture,
        img {
            display: block;
            width: 100%;
        }

        &__image {
            @extend .inset-border;
        }

        &__primary {
            width: 90%;
        }

        &__secondary {
            z-index: 1;
            width: 70%;
            margin-top: -20%;
            position: relative;
        }

        &__caption {
            margin-top: 0.5rem;

            &--1 {
                padding-left: 5px;
            }
        }
    }

    &__text {
        padding: 0 var(--tokens__padding);
    }

    &--left {
        .text_with_media__images {
            &__secondary {
                margin-left: auto;
            }
        }
    }

    &--right {
        .text_with_media__images {
            order: var(--text_with_media__right__order);

            &__primary {
                margin-left: auto;
            }

            &__secondary {
                left: 0;
            }
        }
    }

    &--2 {
        .text_with_media__images__caption--1 {
            max-width: 32%;
        }
    }
}
