.form {
    &__group {
        margin-bottom: 1rem;

        &--columns-two {
            @include Grid(
                2,
                $repeat: true,
                $gap_desktop: 1rem,
                $gap_mobile: 1rem
            );
        }
    }

    &__item {
        position: relative;
    }

    label {
        color: var(--tokens__colors__dark-grey);
        margin-bottom: 5px;
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 1px;
        z-index: 1;
        transition: all 0.25s ease-in;
        background: white;
        padding: 0 10px;
        font-size: 0.875rem;
        max-width: 95%;
        pointer-events: none;
        text-transform: uppercase;
    }

    textarea ~ label {
        top: 20%;
    }

    ::placeholder {
        color: rgba(255, 255, 255, 0);
    }

    input:focus ~ label,
    textarea:focus ~ label,
    input:not(:placeholder-shown) ~ label,
    textarea:not(:placeholder-shown) ~ label {
        top: 0;
        font-size: 0.8125rem;
    }

    button {
        justify-self: flex-start;
    }

    &__success {
        @extend .title, .title--font-family--secondary, .title--tertiary;
    }

    &__error {
        color: #721c24;
        background-color: #f8d7da;
        padding: 0.75rem 1.25rem;
    }

    &__instruction {
        font-family: var(--tokens__font-family__secondary);
        font-style: italic;
        margin-top: 5px;
        color: var(--tokens__colors__dark-grey);
    }

    // Hide the recaptcha
    &__recaptcha,
    &__honeypot {
        display: none !important;
    }
}
