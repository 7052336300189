.video {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  background-color: black; // Default background color helps hold the space before poster image loads and during the transition between the poster image and video load

  iframe,
  video {
    @extend .util__fill;
  }
}
