.container {
    @include Container();

    &--medium {
        @include Container(medium);
    }

    &--small {
        @include Container(small);
    }
}
