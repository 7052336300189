.t__error {
    min-height: 60vh;
    display: flex;
    align-items: center;

    &__inner {
        @include Container();
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;

        header {
            margin: 20px 0;

            p {
                margin-top: 10px;
            }
        }
    }
}
