html {
  font-size: 16px; // This establishes what 1rem is
}

html,
body {
  height: 100%;
}

body {
  font-family: var(--tokens__font-family__primary);
  font-weight: 400;
  background: white;
  color: var(--tokens__colors__darkest-grey);
  position: relative;

  // Disable scrolling when mobile nav or modal is open
  &[data-site-mobile-nav-active="true"],
  &[data-modal-active="true"] {
    height: 100%;
    overflow: hidden;
  }
}

// Hide Alpine.js items until loaded
[x-cloak],
[aria-hidden="true"] {
  display: none;
}

.skip-to-main {
  background: var(--tokens__colors__navy-blue);
  color: white;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 100;
  padding: 0.625rem;
  transform: var(--skip-to-main__transform, translateX(-1000px));
}

.skip-to-main:focus,
.skip-to-main:active {
  --skip-to-main__transform: none;
}
