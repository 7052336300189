.toggle {
  display: flex;
  flex-direction: column;
  color: currentColor;
  padding: 1rem 1.2rem;
  border: 1px solid var(--tokens__colors__medium-grey);
  border-radius: 4rem;
  transition-property: border;

  > div.title--meta {
    display: flex;
    align-items: center;
  }

  & + button {
    margin-left: 1rem;
  }

  @include Hover-Supported() {
    &:hover:not([aria-pressed="true"]) {
      border-color: var(--tokens__colors__blue);
    }
  }

  &:focus {
    border-color: var(--tokens__colors__blue);
  }

  &[aria-selected="true"] {
    border-color: var(--tokens__colors__blue);
    color: var(--tokens__colors__blue);

    // Style the indicator dot
    .toggle__indicator::after {
      transform: translate(-50%, -50%) scale(1);
      visibility: visible;
    }
  }

  &__indicator {
    margin-right: 0.5rem;
    height: 1rem;
    width: 1rem;
    min-width: 1rem;
    background: var(--tokens__colors__medium-grey);
    border-radius: 50%;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      height: 0.375rem;
      width: 0.375rem;
      border-radius: 50%;
      background: var(--tokens__colors__blue);
      visibility: hidden;
      transition: var(--tokens__transition);
      transition-property: transform, visibility;
    }
  }
}
