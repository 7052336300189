:root {
  --filter-buttons__button__width: 175px;
  --filter-buttons__button__height: 120px;
  --filter-buttons__button__font-size: 0.8rem;

  @include MQ-Above(medium) {
    --filter-buttons__button__font-size: 1.1rem;
  }
}

.filter-buttons {
  @extend .link-list;

  --link-list__gutter__top: 1.875rem;

  &__button {
    width: var(--filter-buttons__button__width);
    height: var(--filter-buttons__button__height);
    background: var(--tokens__colors__light-blue);
    box-shadow: var(
      --filter-buttons__button__box-shadow,
      0px 2px 15px 1px rgba(0, 0, 0, 0.1)
    );
    border: 5px solid
      var(
        --filter-buttons__button__border-color,
        var(--tokens__colors__light-blue)
      );
    transition: border 0.25s ease-in, box-shadow 0.25s ease-in;
    position: relative;

    &__image {
      height: 100%;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: var(--filter-buttons__button__img-filter, grayscale(100%));
        transition: filter 0.25s ease-in;
      }
    }

    > h3 {
      --title__font-size: var(--filter-buttons__button__font-size);
      --title__font-weight: 400;

      @extend .title, .title--font-family--secondary;

      text-align: center;
      padding: 0.5rem;
      background: white;
      box-shadow: 0px 2px 15px 1px rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 100%;
      left: 10px;
      right: 10px;
      height: auto;
      transform: translateY(-50%);
    }

    &--active {
      --filter-buttons__button__border-color: var(--tokens__colors__navy-blue);
      --filter-buttons__button__img-filter: none;
    }

    &:hover {
      --filter-buttons__button__box-shadow: none;

      &:not(.filter-buttons__button--active) {
        --filter-buttons__button__border-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  &__label {
    @extend .util__mobile-only;

    text-align: center;
    font-size: 11px;
    letter-spacing: 1px;
    color: #b1b1b1;
    text-transform: uppercase;
  }
}
