:root {
  --cards__title-color: white;

  @include MQ-Above(medium) {
    --card__padding: 2rem;
  }
}

.cards {
  background: var(--tokens__colors__navy-blue);

  &__inner {
    @include Container();
  }

  .header--section {
    color: var(--cards__title-color);

    @include MQ-Above(medium) {
      max-width: 30%;
      margin-bottom: -5rem;
    }
  }

  &__list {
    @include Grid(3, $repeat: true);

    &__card {
      background: white;
      box-shadow: 0px 2px 15px 1px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.15s ease-in;
      padding: var(--card__padding, 1rem);

      &:hover {
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
      }

      &--link {
        padding: 0;

        a:not(.cta) {
          padding: var(--card__padding, 1rem);
        }
      }

      a:not(.cta) {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        footer {
          margin-top: auto;
        }
      }

      h3 {
        @extend .title, .title--tertiary, .title--font-family--secondary;
        font-weight: 400;
        margin-bottom: 0.625rem;
      }

      p {
        color: var(--tokens__colors__dark-grey);
        margin-bottom: 1rem;
      }

      &__media {
        margin-bottom: 1rem;

        svg {
          width: auto;
        }

        &--icon {
          width: auto;
          display: inline-block;
          padding: 1rem;
          background: white;
          box-shadow: var(--tokens__box-shadow);
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  &.cards--light-blue {
    --cards__title-color: black;

    background: var(--tokens__colors__light-blue);
  }

  &.cards--light-grey {
    --cards__title-color: black;

    background: var(--tokens__colors__light-grey);
  }

  &.cards--white {
    --cards__title-color: black;

    background: white;
  }

  &.cards--title-align--center {
    .header--section {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.cards--title-align--right {
    .header--section {
      text-align: right;
      margin-left: auto;
    }
  }
}
