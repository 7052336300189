:root {
  --cta--primary--jumbo__padding: 20px 30px;

  @include MQ-Above(medium) {
    --cta--primary--jumbo__padding: 30px 40px;
  }
}

.cta {
  transition: opacity 0.25s ease-in;

  &:hover {
    opacity: 0.8;
  }

  &--primary {
    background: var(--tokens__colors__navy-blue);
    color: white;
    padding: 15px 20px;

    &--jumbo {
      padding: var(--cta--primary--jumbo__padding);
    }

    &--black {
      background: black;

      &:hover {
        background: var(--tokens__colors__navy-blue);
      }
    }

    &::after {
      content: url("data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.1716 0.46444L14.3536 3.64642C14.5488 3.84168 14.5488 4.15826 14.3536 4.35353L11.1716 7.53551C10.9763 7.73077 10.6597 7.73077 10.4645 7.53551C10.2692 7.34025 10.2692 7.02366 10.4645 6.8284L12.7929 4.49997H0V3.49997H12.7929L10.4645 1.17155C10.2692 0.976284 10.2692 0.659702 10.4645 0.46444C10.6597 0.269178 10.9763 0.269178 11.1716 0.46444Z' fill='%2363c1ff'/%3E%3C/svg%3E%0A");
      display: inline-block;
      margin-left: 0.5rem;
      transition: transform 0.15s ease-in;
    }
  }

  &--with-icon {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;

    svg {
      height: 28px;
      width: auto;
      margin-right: 0.5rem;
    }
  }

  &--secondary {
    color: black;

    &:hover {
      &::after {
        transform: translateX(5px);
      }

      &::before {
        transform: translateX(-5px);
      }
    }

    &::after {
      content: url("data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.1716 0.46444L14.3536 3.64642C14.5488 3.84168 14.5488 4.15826 14.3536 4.35353L11.1716 7.53551C10.9763 7.73077 10.6597 7.73077 10.4645 7.53551C10.2692 7.34025 10.2692 7.02366 10.4645 6.8284L12.7929 4.49997H0V3.49997H12.7929L10.4645 1.17155C10.2692 0.976284 10.2692 0.659702 10.4645 0.46444C10.6597 0.269178 10.9763 0.269178 11.1716 0.46444Z' fill='black'/%3E%3C/svg%3E%0A");
      display: inline-block;
      margin-left: 0.5rem;
      transition: transform 0.15s ease-in;
    }

    &--flipped {
      &::after {
        display: none;
      }

      &::before {
        content: url("data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.32843 7.53556L0.146447 4.35358C-0.0488154 4.15832 -0.0488154 3.84173 0.146447 3.64647L3.32843 0.464491C3.52369 0.269229 3.84027 0.269229 4.03553 0.464491C4.2308 0.659754 4.2308 0.976336 4.03553 1.1716L1.70711 3.50003L14.5 3.50003L14.5 4.50003L1.70711 4.50003L4.03553 6.82845C4.2308 7.02371 4.2308 7.3403 4.03553 7.53556C3.84027 7.73082 3.52369 7.73082 3.32843 7.53556Z' fill='black'/%3E%3C/svg%3E%0A");
        display: inline-block;
        margin-right: 0.5rem;
        transition: transform 0.15s ease-in;
      }
    }

    &--blue {
      color: var(--tokens__colors__navy-blue);

      &::after {
        content: url("data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.1716 0.46444L14.3536 3.64642C14.5488 3.84168 14.5488 4.15826 14.3536 4.35353L11.1716 7.53551C10.9763 7.73077 10.6597 7.73077 10.4645 7.53551C10.2692 7.34025 10.2692 7.02366 10.4645 6.8284L12.7929 4.49997H0V3.49997H12.7929L10.4645 1.17155C10.2692 0.976284 10.2692 0.659702 10.4645 0.46444C10.6597 0.269178 10.9763 0.269178 11.1716 0.46444Z' fill='%2363c1ff'/%3E%3C/svg%3E%0A");
      }

      &.cta--secondary--flipped {
        &::before {
          content: url("data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.32843 7.53556L0.146447 4.35358C-0.0488154 4.15832 -0.0488154 3.84173 0.146447 3.64647L3.32843 0.464491C3.52369 0.269229 3.84027 0.269229 4.03553 0.464491C4.2308 0.659754 4.2308 0.976336 4.03553 1.1716L1.70711 3.50003L14.5 3.50003L14.5 4.50003L1.70711 4.50003L4.03553 6.82845C4.2308 7.02371 4.2308 7.3403 4.03553 7.53556C3.84027 7.73082 3.52369 7.73082 3.32843 7.53556Z' fill='%2363c1ff'/%3E%3C/svg%3E%0A");
        }
      }
    }
  }
}
